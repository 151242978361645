import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import defaultBanner from "./media/genesis-banner.jpg";
import defaultProfile from "./media/genesis-profile.webp";
import CodexTopPanel from "./Codex/CodexTopPanel";
import "./style/Dashboard.css";

const Dashboard = ({ userAddress }) => {
  const ADMIN_ADDRESS = process.env.REACT_APP_ADMIN_ADDRESS?.toLowerCase();
  const navigate = useNavigate();
  const [ctas, setCtas] = useState([]);
  const [filteredCTAs, setFilteredCTAs] = useState([]);
  const [categories, setCategories] = useState(["All categories"]);
  const [collections, setCollections] = useState(["All collections"]);
  const [searchText, setSearchText] = useState("");
  const [searchOwner, setSearchOwner] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("All categories");
  const [selectedCollection, setSelectedCollection] = useState("All collections");
  const [showOnlyOwned, setShowOnlyOwned] = useState(true);
  const [userName, setUserName] = useState("Sedrian");
  const [editingName, setEditingName] = useState(false);
  const [newName, setNewName] = useState("");
  const [userAge, setUserAge] = useState("Loading...");
  const [profilePicture, setProfilePicture] = useState(defaultProfile);
  const [userStats, setUserStats] = useState(null);
  const [userRanks, setUserRanks] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const intervalRef = useRef(null);

  const ctasPerPage = 8;
  const totalPages = Math.ceil(filteredCTAs.length / ctasPerPage);
  const currentCTAs = filteredCTAs.slice((currentPage - 1) * ctasPerPage, currentPage * ctasPerPage);

  const isAdmin = userAddress?.toLowerCase() === ADMIN_ADDRESS;

  const fetchUserProfile = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/users/${userAddress.toLowerCase()}`);
      const user = res.data?.user;
      if (user) {
        setUserName(user.name || "Sedrian");
        setNewName(user.name || "Sedrian");
        const imageURL = `https://sedrax.com/api/uploads/users/${user.walletAddress}.webp`;
        const exists = await axios.head(imageURL).then(() => true).catch(() => false);
        setProfilePicture(exists ? imageURL : defaultProfile);
        if (user.createdAt) updateAge(user.createdAt);
      }
    } catch (err) {
      console.warn("Error fetching user profile", err);
    }
  };

  const updateAge = (createdAt) => {
    const created = new Date(createdAt);
    const update = () => {
      const now = new Date();
      const diff = now - created;
      const days = Math.floor(diff / (1000 * 60 * 60 * 24));
      const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
      setUserAge(`${days} Days : ${hours}h`);
    };
    update();
    clearInterval(intervalRef.current);
    intervalRef.current = setInterval(update, 3600000); // every hour
  };

  const handleNameEdit = async () => {
    if (!newName || newName === userName) return setEditingName(false);
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/users/updateUserName`, {
        walletAddress: userAddress,
        name: newName,
      });
      if (res.data.success) {
        setUserName(newName);
      }
    } catch (err) {
      console.error("Failed to update username:", err);
    } finally {
      setEditingName(false);
    }
  };

  const handleProfileImageUpload = async (e) => {
    const file = e.target.files[0];
    if (!file || !userAddress) return;
    const formData = new FormData();
    formData.append("walletAddress", userAddress);
    formData.append("profileImage", file);
    try {
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/users/uploadProfileImage`, formData);
      fetchUserProfile();
    } catch (err) {
      console.error("❌ Failed to upload profile image:", err);
    }
  };

  const fetchCTAs = async () => {
    try {
      const res = await axios.get("/api/ctas/all");
      const data = res.data || [];
      setCtas(data);
      setCategories(["All categories", ...new Set(data.map(c => c.category || "Unknown"))]);
      setCollections(["All collections", ...new Set(data.map(c => c.collection || "Uncategorized"))]);
    } catch (err) {
      console.error("❌ Failed to fetch CTAs:", err);
    }
  };

  const fetchStats = async () => {
    try {
      const res = await axios.get(`/api/admin/user-stats/${userAddress.toLowerCase()}`);
      if (res.data?.success && res.data.data) {
        setUserStats(res.data.data);
        setUserRanks(res.data.ranks || {});
      }
    } catch (err) {
      console.warn("❌ Error fetching user stats", err);
    }
  };

  useEffect(() => {
    if (!userAddress) return;
    fetchUserProfile();
    fetchCTAs();
    fetchStats();
    return () => clearInterval(intervalRef.current);
  }, [userAddress]);

  useEffect(() => {
    let result = [...ctas];

    if (showOnlyOwned) {
      result = result.filter(c => c.owner.toLowerCase() === userAddress.toLowerCase());
    }

    if (!isAdmin && userAddress) {
      result = result.filter(c => c.owner.toLowerCase() === userAddress.toLowerCase());
    }

    if (searchText) {
      result = result.filter(c => c.baseName?.toLowerCase().includes(searchText.toLowerCase()));
    }

    if (searchOwner && isAdmin) {
      result = result.filter(c => c.owner?.toLowerCase().includes(searchOwner.toLowerCase()));
    }

    if (selectedCategory && selectedCategory !== "All categories") {
      result = result.filter(c => c.category === selectedCategory);
    }

    if (selectedCollection && selectedCollection !== "All collections") {
      result = result.filter(c => c.collection === selectedCollection);
    }

    setFilteredCTAs(result);
    setCurrentPage(1);
  }, [ctas, searchText, searchOwner, selectedCategory, selectedCollection, showOnlyOwned]);

  const totalOwned = filteredCTAs.filter(c => c.owner.toLowerCase() === userAddress.toLowerCase());
  const totalOwnedValue = totalOwned.reduce((acc, c) => acc + Number(c.price || 0), 0);

  const generatePageNumbers = () => {
    const pages = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(i);
    }
    return pages;
  };

  return (
    <div className="dashboard-page">
      <h1 className="page-title">Your Sedrax Codex</h1>

      <div className="profile-section">
        <div className="profile-pic-wrapper">
          <img src={profilePicture} alt="Profile" className="profile-pic" />
          <label className="upload-btn">
            📤
            <input type="file" onChange={handleProfileImageUpload} hidden />
          </label>
        </div>

        <div className="profile-info">
          <p><strong>Wallet:</strong> <span>{userAddress}</span></p>
          <p><strong>Awakened:</strong> <span>{userAge}</span></p>
          <p>
            <strong>Username:</strong> {editingName ? (
              <>
                <input value={newName} onChange={(e) => setNewName(e.target.value)} className="edit-name-input" />
                <button onClick={handleNameEdit}>Save</button>
              </>
            ) : (
              <>
                <span>{userName}</span>
                <button onClick={() => setEditingName(true)}>Edit</button>
              </>
            )}
          </p>
        </div>
      </div>

      <div className="metrics-row">
        <p><strong>Total CTAs:</strong> {totalOwned.length}</p>
        <p><strong>Total wSDR:</strong> {totalOwnedValue.toFixed(2)}</p>
      </div>

      {userStats && <CodexTopPanel userRanks={userRanks} userStats={userStats} />}

      <div className="filter-section">
        <input type="text" placeholder="Search name" value={searchText} onChange={(e) => setSearchText(e.target.value)} className="search-bar" />
        {isAdmin && (
          <input type="text" placeholder="Search by owner" value={searchOwner} onChange={(e) => setSearchOwner(e.target.value)} className="search-bar" />
        )}
        <select value={selectedCategory} onChange={(e) => setSelectedCategory(e.target.value)} className="filter-dropdown">
          {categories.map(c => <option key={c}>{c}</option>)}
        </select>
        <select value={selectedCollection} onChange={(e) => setSelectedCollection(e.target.value)} className="filter-dropdown">
          {collections.map(c => <option key={c}>{c}</option>)}
        </select>
        {isAdmin && (
          <button className="btn_hpt" onClick={() => setShowOnlyOwned(!showOnlyOwned)}>
            {showOnlyOwned ? "Show All CTAs" : "Only My CTAs"}
          </button>
        )}
      </div>

      <div className="ctasContainer">
        {currentCTAs.length ? currentCTAs.map((cta) => (
          <div key={cta.tokenId} className="ctaBox">
            <div className="ctaBox-img-cont-c1">
              <img src={cta.imageURL || defaultBanner} alt={cta.baseName} className="ctaImage" />
            </div>
            <div className="ctaInfo">
              <h4>{cta.baseName || "Unnamed CTA"}</h4>
              <p className="bx_info_cat_c_1"><strong>Category:</strong> {cta.category}</p>
              <p className="bx_info_col_c_1"><strong>Collection:</strong> {cta.collection}</p>
              <p className="col_prpl_c_1"><strong>Price:</strong> {cta.price || "Not listed"}</p>
              <p className="bx_info_rst_c_1"><strong>ID:</strong> {cta.tokenId}</p>
              <div className={`sale-status ${cta.listedForSale ? "listed" : "not-listed"}`}>
                {cta.listedForSale ? "Available" : "Not Listed"}
              </div>
              <button className="btn_hpt" onClick={() => navigate(`/details/${cta.tokenId}`)}>View Details</button>
            </div>
          </div>
        )) : (
          <p className="no-results-msg">No CTAs found</p>
        )}
      </div>

      {totalPages > 1 && (
        <div className="pagination">
          <button onClick={() => setCurrentPage(1)} disabled={currentPage === 1}>First</button>
          <button onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))} disabled={currentPage === 1}>‹</button>
          {generatePageNumbers().map(page => (
            <button key={page} onClick={() => setCurrentPage(page)} className={page === currentPage ? "active-page" : ""}>
              {page}
            </button>
          ))}
          <button onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))} disabled={currentPage === totalPages}>›</button>
          <button onClick={() => setCurrentPage(totalPages)} disabled={currentPage === totalPages}>Last</button>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
